import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../component/useApi";

export const Create = (props: {
  shown: boolean;
  onHide: () => void;
  onSuccess: () => void;
}) => {
  const [projectName, setProjectName] = useState("");
  const [host, setHost] = useState("");
  const [website, setWebsite] = useState(true);
  const [adminEmail, setAdminEmail] = useState("");
  const [projectType, setProjectType] = useState("site-simple");
  const [loading, setLoading] = useState(false);

  const api = useApi();

  const submit = () => {
    if (!projectName || !host || !projectType || !adminEmail) {
      window.alert("Need mandatory field");
      return;
    }
    setLoading(true);
    api
      .post("/api/project/create", {
        name: projectName,
        host: host + ".c.digimaker.com",
        "website-host": host + ".s.digimaker.com",
        website: website,
        type: projectType,
        "admin-email": adminEmail,
      })
      .then((data) => {
        setLoading(false);
        if (data.error === false) {
          props.onSuccess();
        } else {
          window.alert(data.data.message);
        }
      });
  };

  return (
    <div>
      <Dialog
        open={props.shown}
        fullWidth={true}
        onClose={() => props.onHide()}
      >
        <DialogTitle>Creat project</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth={true}>
              <label>Project name:</label>
              <TextField
                size="small"
                required
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <label>Project type:</label>
              <Select
                size="small"
                value={projectType}
                onChange={(e) => setProjectType(e.target.value)}
              >
                <MenuItem value={"site-simple"}>Simple site</MenuItem>
                <MenuItem value={"empty"}>Pure content</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true}>
              <label>Host:</label>
              <TextField
                size="small"
                placeholder="If you input eg.test, it will be test.c.digimaker.com"
                onChange={(e) => setHost(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <label>
                Has website:
                <Checkbox
                  checked={website}
                  onChange={(e) => setWebsite(e.target.checked)}
                />
              </label>
            </FormControl>

            <FormControl fullWidth={true}>
              <label>Admin email:</label>
              <TextField
                type="email"
                required
                size="small"
                placeholder=""
                onChange={(e) => setAdminEmail(e.target.value)}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress size="20px" />}
          <Button variant="contained" onClick={submit} disabled={loading}>
            Submit
          </Button>
          <Button onClick={() => props.onHide()}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
